/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/css/custom.scss'
import React from "react"

import { ContextProvider } from "./src/context/app-context"
import { initCookiePolicy, initOnetrustBanner } from './src/utility/onetrust'
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.GATSBY_RECAPTCHA_PUBLIC_KEY}
    language="it"
    // useRecaptchaNet="[optional_boolean_value]"
    // useEnterprise="[optional_boolean_value]"
    scriptProps={{
      async: true,
      defer: true,
    }}
  >
    <ContextProvider>
      {element}
    </ContextProvider>
  </GoogleReCaptchaProvider>
)

export const onRouteUpdate = (params) => {
  initCookiePolicy();
  if (document.getElementById("onetrust-consent-sdk")) {
    initOnetrustBanner();
  } else {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: false };
    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (
          mutation.type === "childList" &&
          [...mutation.addedNodes].some((node) => node.id === "onetrust-consent-sdk")
        ) {
          initOnetrustBanner();
          // Later, you can stop observing
          observer.disconnect();
        }
      }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(document.body, config);
  }
}